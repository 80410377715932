#sliderauto {
  position: relative;
  height: 700px;
  width: 100%;

  /* margin-top: 0px;
    padding: 0px;
    justify-content: center; */
}
img {
  height: 700px;
  width: 100%;
  /* margin-top: 0px;
    padding: 50px;
    justify-content: center;  */
}

.slider {
  position: relative;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.image {
  width: 100%;
  height: 600px;
  border-radius: 10px;
  margin: 5px auto;
  padding: 0px;
  background-size: auto 300px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: black;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: black;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.shapelogo {
  width: 225px;
  height: 225px;
}

.recaptcha {
  max-width: 100%; /* Ensures ReCAPTCHA doesn't overflow on smaller screens */
  margin: 0 auto; /* Centers ReCAPTCHA horizontally */
}

/* .slide {
    opacity: 1;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 2;
    transition-duration: 1s;
    transform: scale(1.08);
} */

/* .slider{
    position: relative;
    height: 650px;
    width: 100%;
    justify-content: center;
    
  }
  img{
    height: 650px;
    width: 100%;
    margin-top: 0px;
    padding: 50px;
    justify-content: center;
    
  
  } */

/* #slider{
    position: relative;
    height: 600px;
    width: 100%;
    justify-content: center;
    
}
img{
    height: 600px;
    width: 100%;
    margin-top: 0px;
    padding: 50px;
    justify-content: center;
    

} */
