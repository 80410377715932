/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/*
=============== 
Fonts
===============
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(43, 86%, 17%);
  --clr-primary-2: hsl(43, 77%, 27%);
  --clr-primary-3: hsl(43, 72%, 37%);
  --clr-primary-4: hsl(42, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(42, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(43, 89%, 70%);
  --clr-primary-7: hsl(43, 90%, 76%);
  --clr-primary-8: hsl(45, 86%, 81%);
  --clr-primary-9: hsl(45, 90%, 88%);
  --clr-primary-10: hsl(45, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;

  overflow-x: hidden;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-1);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

/* added during recording */

/*
=============== 
Navbar
===============
*/

/* .nav-links {
  display: none;
} */

.nav {
  height: 5rem;
  padding: 1rem;
  /* display: grid; */
  justify-items: center;
  transition: var(--transition);
}
.nav-center {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
}
.nav-header {
  display: grid;
  /* grid-template-columns: 1fr; */
  justify-content: center;
}
.contact-in {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 10px;
  background: #e6d5f4;
  box-shadow: 0px 0px 10px 10px #666;
}
.contact-map {
  width: 100%;
  height: auto;
  flex: 50%;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
}
.contact-form {
  width: 100%;
  height: auto;
  flex: 50%;
  padding: 30px;
  text-align: center;
}

.contact-form h1 {
  margin-bottom: 10px;
}

.contact-form-txt {
  width: 100%;
  height: 40%;
  color: #000;
  border: 1px solid #bcbcbc;
  /* border-radius: 50px; */

  margin-bottom: 20px;
  padding: 15px;
}

.contact-shadow {
  box-shadow: inset 0 10px 10px 0 rgb(0 0 0 / 0.05);
}

.contact-form-txt::placeholder {
  color: #aaa;
}
.contact-form-textarea {
  width: 100%;
  height: 130px;
  color: #000;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
  outline: none;
  margin-bottom: 20px;
  padding: 15px;
  font-family: "poppins", sans-serif;
  resize: none;
}
.contact-form-textarea::placeholder {
  color: #aaa;
}
/* .contact-form-btn {
  width: 50%%;
  border: none;
  outline: none;
  
  background: #cc9af1;
  color: #0e6fa7;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s ease background-color;
} */
/*
=============== 
Sidebar
===============
*/

/*
=============== 
Hero
===============
*/
/* underline added to globals */

/*
=============== 
About
===============
*/
/* section title added to globals */

/*
=============== 
Services
===============
*/
/* small screen layout setup */
/* bg-grey in globals */

/*
=============== 
Projects
===============
*/

/*
=============== 
Connect
===============
*/

/*
=============== 
Skills
===============
*/
/*
=============== 
Timeline
===============
*/

/*
=============== 
Blog
===============
*/

/*
=============== 
Footer
===============
*/
/*
=============== 
Single Page
===============
*/

/*
=============== 
Products Page
===============
*/
